import React, { useEffect, useState } from "react";
import sanityClient from "../client.js";
import BlockContent from "@sanity/block-content-to-react";

function Research() {
  const [content, setContent] = useState(null);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == 'page' && title == "Research"][0]`)
      .then((data) => {
        setContent(data.content);
      })
      .catch(console.error);
  }, []);

  const serializers = {
    marks: {
      link: ({ mark, children }) => {
        const { href } = mark;
        return (
          <a href={href} target="_blank" rel="noreferrer">
            {children}
          </a>
        );
      },
    },
  };

  return (
    <div>
      {content && <BlockContent blocks={content} serializers={serializers} />}
    </div>
  );
}

export default Research;
